import Cookies from "universal-cookie";
import { TPatchWarehouse, TPostCreateWarehouse } from "../redux/types/AdminWarehouseTypes/AdminWarehouse.types";
import { instance } from "./authAPI";

export const dashboardWarehouseAPI = {
    warehouseSetUp(cookies: Cookies, data: TPostCreateWarehouse) {
        return instance.post(`/warehouses/warehouse-storage/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchWarehouse(cookies: Cookies, id: string, data: TPatchWarehouse) {
        return instance.patch(`/warehouses/warehouse-storage/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteWarehouse(cookies: Cookies, id: string) {
        return instance.delete(`/warehouses/warehouse-storage/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
