import axios from "axios";
import Cookies from "universal-cookie";

import {
    Factory,
    IncomingActionTypes,
    IncomingIntegration,
    IncomingShipmentsResult,
    IncomingThunkType,
    IncomingTotals,
    PrepTotals,
    PrepareToShipResult,
    SetErrorIncomingProductType,
    SetFactoriesType,
    SetIncomingIntegrationsType,
    SetIncomingProductAllType,
    SetIncomingProductType,
    SetIncomingTotalsType,
    SetLoadingIntegrationsType,
    SetOutgoingTotalsType,
    TBulkCommercialInvoiceItem,
    TBulkCommercialInvoiceState,
    TLoadingStates,
    TOutgoingOrdersDetailsItems,
    TOutgoingOrdersDetailsOptions,
    TOutgoingOrdersDetailsState,
    TOutgoingOrdersFilter,
    TOutgoingOrdersResults,
    TOutgoingOrdersState,
    TPrepareToShipState,
    TSetBulkCommercialInvoiceProducts,
    TSetBulkSkuInboundTemplateProducts,
    TSetIncomingWarehouses,
    TSetLoading,
    TSetLoadingStates,
    TSetOutgoingOrders,
    TSetOutgoingOrdersAll,
    TSetOutgoingOrdersDetails,
    TSetPrepareToShipProduct,
} from "./types/IncomingTypes/incoming.types";
import { getBrandProduct } from "./addProductReducer";
import { TWarehouse } from "./types/WarehouseTypes/warehouse.types";
import { displayError } from "../hooks/useErrorHandler";
import { sortIntegrationList } from "../components/common/utils";
import { incomingProduct, outgoingProduct } from "../api/incomingProductApi";
import { TRequestLazySearchParams, TSKUdropAllMarketsWithEU, TSKUdropAllVendorMarkets } from "./types/common/common.types";

export const SET_INCOMING_PRODUCT = "SET_INCOMING_PRODUCT";
export const SET_INCOMING_TOTAL = "SET_INCOMING_TOTAL";
export const SET_LOADING = "SET_LOADING";
export const SET_PREPARE_TO_SHIP_PRODUCT = "SET_PREPARE_TO_SHIP_PRODUCT";
export const SET_FACTORIES = "SET_FACTORIES";
export const SET_PREPARE_TO_SHIP_PRODUCT_NEW = "SET_PREPARE_TO_SHIP_PRODUCT_NEW";
export const SET_ERROR_INCOMING_PRODUCT = "SET_ERROR_INCOMING_PRODUCT";
export const SET_INCOMING_PRODUCT_ALL = "SET_INCOMING_PRODUCT_ALL";
export const SET_OUTGOING_TOTAL = "SET_OUTGOING_TOTAL";
export const SET_INCOMING_PRODUCT_DELETE = "SET_INCOMING_PRODUCT_DELETE";
export const SET_PRODUCT_REQUIRE_ACTION = "SET_PRODUCT_REQUIRE_ACTION";
export const SET_INCOMING_INTEGRATIONS = "SET_INCOMING_INTEGRATIONS";
export const SET_LOADING_INTEGRATIONS = "SET_LOADING_INTEGRATIONS";
export const SET_BULK_COMMERCIAL_INVOICE_PRODUCTS = "SET_BULK_COMMERCIAL_INVOICE_PRODUCTS";
export const SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS = "SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_OUTGOING_ORDERS = "SET_OUTGOING_ORDERS";
export const SET_OUTGOING_ORDERS_ALL = "SET_OUTGOING_ORDERS_ALL";
export const SET_OUTGOING_ORDERS_DETAILS = "SET_OUTGOING_ORDERS_DETAILS";
export const SET_INCOMING_WAREHOUSES = "SET_INCOMING_WAREHOUSES";

type InitialStateType = {
    incomingProduct: null | IncomingShipmentsResult[];
    incomingProductAll: null | IncomingShipmentsResult[];
    incomingTotals: null | IncomingTotals;
    outgoingTotals: null | PrepTotals;
    prepareToShipProduct: null | TPrepareToShipState;
    isLoading: boolean;
    isErrorIncomingProduct: null | string | boolean;
    searchFactoryValue: null | Factory[];
    incomingIntegrations: null | IncomingIntegration[];
    isLoadingIntegrations: boolean;
    bulkCommercialInvoiceProducts: TBulkCommercialInvoiceState | null;
    bulkSkuInboundTemplateProducts: TPrepareToShipState | null;
    loadingStates: TLoadingStates;
    outgoingOrders: TOutgoingOrdersState;
    outgoingOrdersAll: TOutgoingOrdersState;
    outgoingOrdersDetails: TOutgoingOrdersDetailsState;
    incomingWarehouses: TWarehouse[] | null;
};

let initialState: InitialStateType = {
    incomingProduct: null,
    incomingProductAll: null,
    incomingTotals: null,
    outgoingTotals: null,
    prepareToShipProduct: null,
    isLoading: false,
    isErrorIncomingProduct: null,
    searchFactoryValue: null,
    incomingIntegrations: null,
    isLoadingIntegrations: false,
    bulkCommercialInvoiceProducts: null,
    bulkSkuInboundTemplateProducts: null,
    loadingStates: {
        isLoadingIncomingProducts: false,
        isLoadingBulkCommercialInvoice: false,
        isLoadingBulkSkuInboundModal: false,
        isLoadingSKUdropOutgoingOrders: false,
        isLoadingOwnFFOutgoingOrders: false,
        isLoadingQuoteOutgoingOrders: false,
        isLoadingQuoteOutgoingOrderDetails: false,
        isLoadingIncomingAvailableWarehouses: false,
    },
    outgoingOrders: {
        skudrop: null,
        own_ff: null,
        quote: null,
    },
    outgoingOrdersAll: {
        skudrop: null,
        own_ff: null,
        quote: null,
    },
    outgoingOrdersDetails: {
        quote: null,
    },
    incomingWarehouses: null,
};

const incomingProductReducer = (state = initialState, action: IncomingActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_INCOMING_INTEGRATIONS: {
            return {
                ...state,
                incomingIntegrations: action.data,
            };
        }
        case SET_INCOMING_PRODUCT: {
            return {
                ...state,
                incomingProduct: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_PRODUCT_ALL: {
            return {
                ...state,
                incomingProductAll: action.data,
                isLoading: false,
            };
        }
        case SET_INCOMING_TOTAL: {
            return {
                ...state,
                incomingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_OUTGOING_TOTAL: {
            return {
                ...state,
                outgoingTotals: action.data,
                isLoading: false,
            };
        }
        case SET_PREPARE_TO_SHIP_PRODUCT: {
            return {
                ...state,
                prepareToShipProduct: { ...state?.prepareToShipProduct, [action.integrationId]: action.data },
            };
        }
        case SET_FACTORIES: {
            return {
                ...state,
                searchFactoryValue: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_ERROR_INCOMING_PRODUCT: {
            return {
                ...state,
                isErrorIncomingProduct: action.data,
            };
        }
        case SET_LOADING_INTEGRATIONS: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_BULK_COMMERCIAL_INVOICE_PRODUCTS: {
            return {
                ...state,
                bulkCommercialInvoiceProducts: { ...state?.bulkCommercialInvoiceProducts, [action.integrationId]: action.data },
            };
        }
        case SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS: {
            return {
                ...state,
                bulkSkuInboundTemplateProducts: { ...state?.bulkSkuInboundTemplateProducts, [action.integrationId]: action.data },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_OUTGOING_ORDERS: {
            return {
                ...state,
                outgoingOrders: { ...state.outgoingOrders, [action.option]: action.data },
            };
        }
        case SET_OUTGOING_ORDERS_ALL: {
            return {
                ...state,
                outgoingOrdersAll: { ...state.outgoingOrders, [action.option]: action.data },
            };
        }
        case SET_OUTGOING_ORDERS_DETAILS: {
            return {
                ...state,
                outgoingOrdersDetails: { ...state.outgoingOrdersDetails, [action.option]: action.data },
            };
        }
        case SET_INCOMING_WAREHOUSES: {
            return {
                ...state,
                incomingWarehouses: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetIncomingProduct = (data: IncomingShipmentsResult[]): SetIncomingProductType => ({
    type: SET_INCOMING_PRODUCT,
    data,
});
export const SetIncomingProductAll = (data: IncomingShipmentsResult[]): SetIncomingProductAllType => ({
    type: SET_INCOMING_PRODUCT_ALL,
    data,
});
export const SetIncomingTotals = (data: IncomingTotals): SetIncomingTotalsType => ({
    type: SET_INCOMING_TOTAL,
    data,
});
export const SetOutgoingTotals = (data: PrepTotals): SetOutgoingTotalsType => ({
    type: SET_OUTGOING_TOTAL,
    data,
});
export const SetPrepareToShipProduct = (data: PrepareToShipResult[], integrationId: string): TSetPrepareToShipProduct => ({
    type: SET_PREPARE_TO_SHIP_PRODUCT,
    data,
    integrationId,
});
export const SetFactories = (data: Factory[]): SetFactoriesType => ({
    type: SET_FACTORIES,
    data,
});
export const SetLoading = (loading: boolean): TSetLoading => ({
    type: SET_LOADING,
    data: loading,
});
export const SetErrorIncomingProduct = (error: null | boolean | string): SetErrorIncomingProductType => ({
    type: SET_ERROR_INCOMING_PRODUCT,
    data: error,
});
export const SetIncomingIntegrations = (data: IncomingIntegration[]): SetIncomingIntegrationsType => ({
    type: SET_INCOMING_INTEGRATIONS,
    data,
});
export const SetLoadingIntegrations = (loading: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATIONS,
    data: loading,
});
export const SetBulkCommercialInvoiceProducts = (data: TBulkCommercialInvoiceItem[], integrationId: string): TSetBulkCommercialInvoiceProducts => ({
    type: SET_BULK_COMMERCIAL_INVOICE_PRODUCTS,
    data,
    integrationId,
});

export const SetBulkSkuInboundTemplateProducts = (data: PrepareToShipResult[], integrationId: string): TSetBulkSkuInboundTemplateProducts => ({
    type: SET_BULK_SKU_INBOUND_TEMPLATE_PRODUCTS,
    data,
    integrationId,
});

export const SetLoadingStates = (data: { [key in keyof TLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetOutgoingOrders = (option: TOutgoingOrdersFilter, data: TOutgoingOrdersResults[]): TSetOutgoingOrders => ({
    type: SET_OUTGOING_ORDERS,
    option,
    data,
});

export const SetOutgoingOrdersAll = (option: TOutgoingOrdersFilter, data: TOutgoingOrdersResults[]): TSetOutgoingOrdersAll => ({
    type: SET_OUTGOING_ORDERS_ALL,
    option,
    data,
});

export const SetOutgoingOrdersDetails = (option: TOutgoingOrdersDetailsOptions, data: TOutgoingOrdersDetailsItems): TSetOutgoingOrdersDetails => ({
    type: SET_OUTGOING_ORDERS_DETAILS,
    option,
    data,
});

export const SetIncomingWarehouses = (data: TWarehouse[]): TSetIncomingWarehouses => ({
    type: SET_INCOMING_WAREHOUSES,
    data,
});

export const getIncomingProduct = (cookies: Cookies, country_code?: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: true }));
            const response = await incomingProduct.getIncomingProduct(cookies, country_code);
            dispatch(SetIncomingProduct(response.data.results));
            dispatch(SetIncomingTotals(response.data.incoming_totals));
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getIncomingProductAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    searchTerm: string,
    setProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingProduct.getIncomingProductAll(cookies, items, offset, searchTerm, country_code, brand);
            dispatch(SetIncomingProductAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOutgoingOrders = (option: TOutgoingOrdersFilter, queryParams?: Partial<{ country_code: TSKUdropAllVendorMarkets }>): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            if (option === "skudrop") {
                dispatch(SetLoadingStates({ isLoadingSKUdropOutgoingOrders: true }));

                const response = await outgoingProduct.getSKUdropOutgoingOrders(cookies, queryParams || {});

                dispatch(SetOutgoingOrders(option, response.data.results));
                dispatch(SetOutgoingTotals(response.data.prep_totals));

                dispatch(SetLoadingStates({ isLoadingSKUdropOutgoingOrders: false }));
            } else if (option === "own_ff") {
                dispatch(SetLoadingStates({ isLoadingOwnFFOutgoingOrders: true }));

                const response = await outgoingProduct.getOwnFFOutgoingOrders(cookies, queryParams || {});

                dispatch(SetOutgoingOrders(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingOwnFFOutgoingOrders: false }));
            } else {
                dispatch(SetLoadingStates({ isLoadingQuoteOutgoingOrders: true }));

                const response = await outgoingProduct.getQuoteOutgoingOrders(cookies, queryParams || {});

                dispatch(SetOutgoingOrders(option, response.data.results));

                dispatch(SetLoadingStates({ isLoadingQuoteOutgoingOrders: false }));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOutgoingProductAll = (
    option: TOutgoingOrdersFilter,
    queryParams: TRequestLazySearchParams & Partial<{ country_code: TSKUdropAllVendorMarkets; brand: string }>,
    setHasNextItems: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            if (option === "skudrop") {
                const response = await outgoingProduct.getSKUdropOutgoingOrders(cookies, queryParams);

                setHasNextItems(!!response.data.next);

                dispatch(SetOutgoingOrdersAll(option, response.data.results));
            } else if (option === "own_ff") {
                const response = await outgoingProduct.getOwnFFOutgoingOrders(cookies, queryParams);

                setHasNextItems(!!response.data.next);

                dispatch(SetOutgoingOrdersAll(option, response.data.results));
            } else {
                const response = await outgoingProduct.getQuoteOutgoingOrders(cookies, queryParams);

                setHasNextItems(!!response.data.next);

                dispatch(SetOutgoingOrdersAll(option, response.data.results));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getPrepareToShipProduct = (
    cookies: Cookies,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            if (!offset) {
                dispatch(SetLoading(true));
            }
            dispatch(SetErrorIncomingProduct(null));
            const response = await incomingProduct.getPrepareToShip(cookies, integrationId, searchTerm, items, offset);
            setProductAllNext(!!response.data.next);
            dispatch(SetPrepareToShipProduct(response.data.results, integrationId));
            dispatch(SetFactories(response.data.factories));
            if (!offset) {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteIncomingProduct = (cookies: Cookies, product: IncomingShipmentsResult, activeMarket: string | undefined): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingIncomingProducts: true }));

            await incomingProduct.deleteIncomingProduct(cookies, product.id);

            dispatch(getIncomingProduct(cookies, activeMarket));

            if (product.shipment_items[0].product_brand) {
                dispatch(getBrandProduct(cookies, product.shipment_items[0].product_brand));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingIncomingProducts: false }));
                displayError(e.response.data, "Something went wrong while deleting the product");
            }
        }
    };
};

export const postPrepareToShipProduct = (
    cookies: Cookies,
    data: FormData,
    integrationId: string,
    searchTerm: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await incomingProduct.postPrepareToShip(cookies, data);
            if (response.data) {
                console.log(response.data);
                dispatch(SetErrorIncomingProduct(false));
                dispatch(getPrepareToShipProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
            }
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                dispatch(getPrepareToShipProduct(cookies, integrationId, searchTerm, items, offset, setProductAllNext));
                displayError(e.response?.data, "Something went wrong...");
            }
        }
    };
};

export const getIncomingIntegrations = (cookies: Cookies): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            const response = await incomingProduct.getIncomingIntegrations(cookies);
            dispatch(SetIncomingIntegrations(sortIntegrationList(response.data.results || [])));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getBulkCommercialInvoiceProducts = (
    cookies: Cookies,
    integrationId: string,
    searchValue: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            const response = await incomingProduct.getBulkCommercialInvoiceProducts(cookies, integrationId, searchValue, items, offset);
            setProductAllNext(!!response.data.next);
            dispatch(SetBulkCommercialInvoiceProducts(response.data.results, integrationId));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postBulkCommercialInvoice = (
    cookies: Cookies,
    productsToProcess: TBulkCommercialInvoiceItem[],
    invoiceFile: File,
    integration: string,
    onSuccess: () => void,
    processUpdatedCommercialInvoice: (updatedProducts: PrepareToShipResult[]) => void
): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: true }));
            const formData = new FormData();

            formData.append("commercial_invoice", invoiceFile);

            productsToProcess.forEach((product) => {
                formData.append("product_ids", product.id);
            });

            await incomingProduct.postBulkCommercialInvoice(cookies, formData);

            const response = await incomingProduct.getPrepareToShip(cookies, integration, productsToProcess.map((item) => item.sku).join(","), 1000, 0);

            processUpdatedCommercialInvoice(response.data.results);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkCommercialInvoice: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: true }));

            const response = await incomingProduct.getBulkSkuInboundTemplate(cookies, integrationId);

            window.open(response.data, "_blank");

            dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkSkuInboundModal: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postBulkSkuInboundTemplate = (cookies: Cookies, integrationId: string, formData: FormData, setProductAllNext: (value: boolean) => void): IncomingThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            dispatch(SetErrorIncomingProduct(null));

            const response1 = await incomingProduct.getPrepareToShip(cookies, integrationId, "", 20, 0);

            const response2 = await incomingProduct.postBulkSkuInboundTemplate(cookies, integrationId, formData);

            const productsToReceive = response2.data
                .filter((item) => !response1.data.results.some((obj) => obj.sku === item.sku))
                .map((item) => item.sku)
                .join(","); // find products difference between bulk sku inbound template products and prepare to ship products

            let response3;

            if (productsToReceive) {
                response3 = await incomingProduct.getPrepareToShip(cookies, integrationId, productsToReceive, 1000, 0);
            }

            setProductAllNext(!!response1.data.next);

            dispatch(SetPrepareToShipProduct([...(response3?.data?.results || []), ...response1.data.results], integrationId));
            dispatch(SetFactories(response1.data.factories));

            dispatch(SetBulkSkuInboundTemplateProducts(response2.data, integrationId));

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(getPrepareToShipProduct(cookies, integrationId, "", 20, 0, setProductAllNext));
                displayError(e.response.data);
            }
        }
    };
};

// Outgoing Orders Details

export const getOutgoingOrdersDetails = (option: TOutgoingOrdersDetailsOptions, queryParams: { id: string }): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            if (option === "quote") {
                dispatch(SetLoadingStates({ isLoadingQuoteOutgoingOrderDetails: true }));

                const response = await outgoingProduct.getQuoteOutgoingOrderDetails(cookies, queryParams);

                dispatch(SetOutgoingOrdersDetails(option, response.data));

                dispatch(SetLoadingStates({ isLoadingQuoteOutgoingOrderDetails: false }));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (option === "quote") {
                    dispatch(SetLoadingStates({ isLoadingQuoteOutgoingOrderDetails: false }));
                }

                displayError(e.response.data);
            }
        }
    };
};

export const getIncomingAvailableWarehouses = (): IncomingThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;

            dispatch(SetLoadingStates({ isLoadingIncomingAvailableWarehouses: true }));

            const response = await incomingProduct.getIncomingAvailableWarehouses(cookies);

            dispatch(SetIncomingWarehouses(response.data.results));

            dispatch(SetLoadingStates({ isLoadingIncomingAvailableWarehouses: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export default incomingProductReducer;
