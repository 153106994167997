import { instance } from "./authAPI";
import { TTrackingResponse, TTrackingItem, TTrackingColumnItem, TTrackingColumnItemsResponse, TTrackingShipmentInformation } from "../redux/types/TrackingTypes/tracking.types";
import { TRACKING_FILTER_LABEL_TO_VALUE } from "../components/Dashboard/TrackShipments/constants/constants";
import Cookies from "universal-cookie";

export const trackingApi = {
    getTrackingList: (cookies: Cookies, searchValue: string, filter: string, limit: number, offset: number) => {
        return instance.get<void, TTrackingResponse<TTrackingItem>>(
            `/tracking/tracking_table/?limit=${limit}&offset=${offset}${searchValue ? `&search=${window.encodeURIComponent(searchValue)}` : ""}${filter ? `&filter_by=${TRACKING_FILTER_LABEL_TO_VALUE[filter as keyof typeof TRACKING_FILTER_LABEL_TO_VALUE]}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getTrackingColumns: (cookies: Cookies, userId: string) => {
        return instance.get<void, TTrackingColumnItemsResponse>(`users/column_for_display_tracking_table/${userId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateTrackingColumns: (cookies: Cookies, userId: string, columns: TTrackingColumnItem[]) => {
        return instance.patch<void, void>(
            `users/column_for_display_tracking_table/${userId}/`,
            {
                columns_for_tracking: columns,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getTrackingMapInfo: (cookies: Cookies, trackingId: string) => {
        return instance.get<void, TTrackingShipmentInformation>(`tracking/tracking_table_map/${trackingId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
