// The order of import is important
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/dashboard.css";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import useWebSocket from "react-use-websocket";

import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import { Auth, getNotifications } from "./redux/authReducer";
import { getDashboardWarehouses } from "./redux/warehouseReducer";
import { useAppDispatch, useAppSelector } from "./redux/ReduxStore";

import AppRouter from "./AppRouter";

import preloader from "./assets/video/preloader.webp";

function App() {
    const dispatch = useAppDispatch();

    const isAuthLoading = useAppSelector((state) => state.auth.isAuthLoading);
    const cookies = useAppSelector((state) => state.auth.cookies);
    const user = useAppSelector((state) => state.auth.user);
    const tenantType = useAppSelector((state) => state.auth.tenantType);
    const { isLoadingDashboardWarehouses } = useAppSelector((state) => state.warehouse.loadingStates);

    const hostName = window.location.origin;

    const [socketUrl] = useState(`wss://${hostName}/wss/notifications/`.replace("https://", ""));

    const { sendJsonMessage, lastMessage } = useWebSocket(socketUrl, {
        onOpen: () => console.log("opened"),
    });

    useEffect(() => {
        dispatch(Auth(cookies));
    }, [cookies]);

    useEffect(() => {
        if (user && tenantType === "WAREHOUSE") {
            dispatch(getDashboardWarehouses());
        }
    }, [user, tenantType]);

    useEffect(() => {
        sendJsonMessage({
            action: "subscribe_to_notification_activity",
            request_id: new Date().getTime(),
            tenant_id: user && user.get_current_tenant_id,
        });
    }, [user, tenantType]);

    useEffect(() => {
        user && user.get_current_tenant_id && dispatch(getNotifications(cookies, 10));
    }, [lastMessage]);

    if (isAuthLoading || isLoadingDashboardWarehouses) {
        return (
            <Container className="spiner-container flex-column">
                <img src={preloader} style={{ marginLeft: 40 }} alt="Loading..." width="150" height="150"></img>
            </Container>
        );
    }

    return <AppRouter />;
}

export default App;
