import axios from "axios";
import Cookies from "universal-cookie";

import { NavigateFunction } from "react-router";

import {
    ActionAddProductType,
    AddProductFromAmazonCategoriesType,
    AddProductFromAmazonRequest,
    AddProductThunkType,
    EditProductDataType,
    TAddProductIntegration,
    ProductActionResult,
    ProductType,
    SetAmazonProductLoadingType,
    SetBandsProductType,
    SetBrandsType,
    SetFilesUploadingType,
    SetIntegrationListType,
    SetIsLoadingSkusFileType,
    SetLoadingIntegrationsType,
    SetProductActionAllCountType,
    SetProductActionCountType,
    SetProductAllType,
    SetProductType,
    SetSkusFileUploadResponseType,
    TActiveMeasurements,
    TAddProductLoadingStates,
    TSetActiveMeasurements,
    TSetLoadingAddProductStates,
    TBulkSkuUploadProductData,
    TSetVendorMappingIntegrations,
    TIntegrationSourceTypes,
    TVendorMappingIntegration,
    TSetVendorBulkProductsData,
    TBulkVendorUploadProductData,
} from "./types/AddProductTypes/AddProduct.types";
import { SetLoadingType } from "./types/AuthTypes/auth.types";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import { addProductsOrBrandsAPI, dashboardAPI, vendorProductsAPI } from "../api/addProductAPI";
import { BrandProductType, TStateBrandProducts } from "../components/Dashboard/types/products.types";
import { TSKUdropVendorMarkets } from "./types/common/common.types";

export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCT_ALL = "SET_PRODUCT_ALL";
export const SET_PRODUCT_ACTION = "SET_PRODUCT_ACTION";
export const SET_PRODUCT_ACTION_ALL = "SET_PRODUCT_ACTION_ALL";
export const SET_BRAND_PRODUCT = "SET_BRAND_PRODUCT";
export const SET_BRANDS = "SET_BRANDS";
export const SET_LOADING = "SET_LOADING";
export const SET_PRODUCTS_FROM_AMAZON_ALL = "SET_PRODUCTS_FROM_AMAZON_ALL";
export const SET_AMAZON_PRODUCT_LOADING = "SET_AMAZON_PRODUCT_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_INTEGRATIONS_LIST = "SET_INTEGRATIONS_LIST";
export const SET_LOADING_INTEGRATION = "SET_LOADING_INTEGRATION";
export const SET_FILES_UPLOADING = "SET_FILES_UPLOADING";
export const SET_SKUS_UPLOAD_RESPONSE = "SET_SKUS_UPLOAD_RESPONSE";
export const SET_IS_LOADING_SKUS_FILE = "SET_IS_LOADING_SKUS_FILE";
export const SET_ACTIVE_MEASUREMENTS = "SET_ACTIVE_MEASUREMENTS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_VENDOR_MAPPING_INTEGRATIONS = "SET_VENDOR_MAPPING_INTEGRATIONS";
export const SET_VENDOR_BULK_PRODUCTS_DATA = "SET_VENDOR_BULK_PRODUCTS_DATA";

type InitialStateType = {
    product_list: null | ProductType[];
    product_list_all: null | ProductType[];
    product_action_list: null | ProductActionResult[];
    product_action_list_all: null | ProductActionResult[];
    isLoading: boolean;
    isAmazonProductLoading: boolean;
    brandProducts: TStateBrandProducts | null;
    integrationsList: null | TAddProductIntegration[];
    isLoadingIntegrations: boolean;
    brands: string[];
    isLoadingUserDashboard: boolean;
    isUploadingFiles: boolean;
    skusUploadResponse: TBulkSkuUploadProductData[] | null;
    isLoadingSkusFile: boolean;
    activeMeasurements: TActiveMeasurements | null;
    loadingStates: TAddProductLoadingStates;
    vendorMappingIntegrations: TVendorMappingIntegration[] | null;
    vendorBulkProductsData: TBulkVendorUploadProductData[] | null;
};

let initialState: InitialStateType = {
    product_list: null,
    product_list_all: null,
    product_action_list: null,
    product_action_list_all: null,
    isLoading: false,
    isAmazonProductLoading: false,
    brandProducts: null,
    brands: [],
    integrationsList: null,
    isLoadingIntegrations: false,
    isLoadingUserDashboard: false,
    isUploadingFiles: false,
    skusUploadResponse: null,
    isLoadingSkusFile: false,
    activeMeasurements: null,
    loadingStates: {
        isLoadingUploadedProducts: false,
        isLoadingProductsRequiredAction: false,
        isLoadingBrands: false,
        isLoadingBrandProducts: false,
        isLoadingUSAsyncBulkUpload: false,
        isLoadingUKAsyncBulkUpload: false,
        isLoadingDEAsyncBulkUpload: false,
        isLoadingFRAsyncBulkUpload: false,
        isLoadingVSAsyncBulkUpload: false,
        isLoadingUSAddProduct: false,
        isLoadingUKAddProduct: false,
        isLoadingDEAddProduct: false,
        isLoadingFRAddProduct: false,
        isLoadingVSAddProduct: false,
        isLoadingMappingIntegrations: false,
        isLoadingVendorProductsImport: false,
        isLoadingCreatingVendorProduct: false,
        isLoadingVendorProductsRemapping: false,
        isLoadingVendorBulkProductsTemplate: false,
        isLoadingBulkVendorProductsDataUpload: false,
    },
    vendorMappingIntegrations: null,
    vendorBulkProductsData: null,
};

const productsReducer = (state = initialState, action: ActionAddProductType): InitialStateType => {
    switch (action.type) {
        case SET_IS_LOADING_SKUS_FILE: {
            return {
                ...state,
                isLoadingSkusFile: action.data,
            };
        }
        case SET_SKUS_UPLOAD_RESPONSE: {
            return {
                ...state,
                skusUploadResponse: action.data,
            };
        }
        case SET_INTEGRATIONS_LIST: {
            return {
                ...state,
                integrationsList: action.data,
            };
        }
        case SET_AMAZON_PRODUCT_LOADING: {
            return {
                ...state,
                isAmazonProductLoading: action.data,
            };
        }
        case SET_PRODUCT: {
            return {
                ...state,
                product_list: action.data,
            };
        }
        case SET_PRODUCT_ACTION: {
            return {
                ...state,
                product_action_list: action.data,
            };
        }
        case SET_PRODUCT_ACTION_ALL: {
            return {
                ...state,
                product_action_list_all: action.data,
            };
        }
        case SET_PRODUCT_ALL: {
            return {
                ...state,
                product_list_all: action.data,
            };
        }
        case SET_BRANDS: {
            return {
                ...state,
                brands: action.data,
            };
        }
        case SET_BRAND_PRODUCT: {
            return {
                ...state,
                brandProducts: { ...state?.brandProducts, [action.brand]: action.brandProduct },
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_INTEGRATION: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_FILES_UPLOADING: {
            return {
                ...state,
                isUploadingFiles: action.data,
            };
        }
        case SET_ACTIVE_MEASUREMENTS: {
            return {
                ...state,
                activeMeasurements: { ...state?.activeMeasurements, [action.countryCode]: { ...state?.activeMeasurements?.[action.countryCode], ...action.data } },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        case SET_VENDOR_MAPPING_INTEGRATIONS: {
            return {
                ...state,
                vendorMappingIntegrations: action.data,
            };
        }
        case SET_VENDOR_BULK_PRODUCTS_DATA: {
            return {
                ...state,
                vendorBulkProductsData: action.data,
            };
        }
        default:
            return state;
    }
};
export const SetIntegrationList = (data: TAddProductIntegration[] | null): SetIntegrationListType => ({
    type: SET_INTEGRATIONS_LIST,
    data,
});
export const SetProductCount = (response: ProductType[]): SetProductType => ({
    type: SET_PRODUCT,
    data: response,
});
export const SetProductActionCount = (response: ProductActionResult[]): SetProductActionCountType => ({
    type: SET_PRODUCT_ACTION,
    data: response,
});
export const SetProductActionAllCount = (response: ProductActionResult[]): SetProductActionAllCountType => ({
    type: SET_PRODUCT_ACTION_ALL,
    data: response,
});
export const SetProductCountAll = (data: ProductType[]): SetProductAllType => ({
    type: SET_PRODUCT_ALL,
    data,
});
export const SetBrands = (response: string[]): SetBrandsType => ({
    type: SET_BRANDS,
    data: response,
});
export const SetBrandsProduct = (response: BrandProductType, brand: string): SetBandsProductType => ({
    type: SET_BRAND_PRODUCT,
    brandProduct: response,
    brand: brand,
});
export const SetLoading = (response: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: response,
});
export const SetLoadingIntegrations = (response: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATION,
    data: response,
});

export const SetAmazonProductLoading = (response: boolean): SetAmazonProductLoadingType => ({
    type: SET_AMAZON_PRODUCT_LOADING,
    data: response,
});

export const SetFilesUploading = (data: boolean): SetFilesUploadingType => ({
    type: SET_FILES_UPLOADING,
    data,
});
export const SetSkusUploadFileResponse = (data: TBulkSkuUploadProductData[] | null): SetSkusFileUploadResponseType => ({
    type: SET_SKUS_UPLOAD_RESPONSE,
    data,
});
export const SetIsLoadingSkusFile = (data: boolean): SetIsLoadingSkusFileType => ({
    type: SET_IS_LOADING_SKUS_FILE,
    data,
});
export const SetActiveMeasurements = (countryCode: string, data: { isPounds?: boolean; isInches?: boolean }): TSetActiveMeasurements => ({
    type: SET_ACTIVE_MEASUREMENTS,
    countryCode,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TAddProductLoadingStates]?: boolean }): TSetLoadingAddProductStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const SetVendorMappingIntegrations = (data: TVendorMappingIntegration[]): TSetVendorMappingIntegrations => ({
    type: SET_VENDOR_MAPPING_INTEGRATIONS,
    data,
});

export const SetVendorBulkProductsData = (data: TBulkVendorUploadProductData[] | null): TSetVendorBulkProductsData => ({
    type: SET_VENDOR_BULK_PRODUCTS_DATA,
    data,
});

export const getIntegrationsList = (token: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));

            let response = await dashboardAPI.getIntegrationsList(token);

            // loading management for products import
            if (response.data.results.some((el) => el.task_status === "WT" || el.task_status === "IP")) {
                dispatch(SetAmazonProductLoading(true));
            } else if (response.data.results.some((el) => el.task_status === "FN" || el.task_status === "FR")) {
                dispatch(SetAmazonProductLoading(false));
            }

            // loading management for async bulk upload
            if (response.data.results.some((el) => el.bulk_upload_status === "RC")) {
                response.data.results.forEach((el) => {
                    if (el.bulk_upload_status === "RC") {
                        dispatch(SetLoadingStates({ [`isLoading${el.country_code}AsyncBulkUpload`]: false }));
                    }
                });
            }

            dispatch(SetIntegrationList(response.data.results));

            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const editProduct = (cookies: Cookies, product: ProductType, data: EditProductDataType, activeMarket: TSKUdropVendorMarkets | null): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.editProduct(cookies.get("token"), product.carton_id, data);

            // prettier-ignore
            const promises = [
                dispatch(ShipmentUploadedAction(cookies.get("token"), activeMarket)),
                dispatch(getBrandProduct(cookies, product.get_product_by_id.brand))
            ];

            await Promise.all(promises);

            if (data.category?.shipping_declaration_document.length) {
                displaySuccess("New documents will be updated soon");
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong during a product update");
            }
        }
    };
};

export const deleteProduct = (cookies: Cookies, product: ProductType, activeMarket: TSKUdropVendorMarkets | null): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.deleteProduct(cookies.get("token"), product.carton_id);
            dispatch(ShipmentUploadedAction(cookies.get("token"), activeMarket));
            dispatch(getBrandProduct(cookies, product.get_product_by_id.brand));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const deleteHSCode = (cookies: Cookies, product: ProductType, hsId: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.deleteHSCode(cookies, hsId);
            dispatch(ShipmentUploadedAction(cookies.get("token"), null));
            dispatch(getBrandProduct(cookies, product.get_product_by_id.brand));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong when deleting the HS code");
            }
        }
    };
};

export const getBrandProduct = (cookies: Cookies, brand: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBrandProducts: true }));
            let response = await dashboardAPI.getBrandProduct(cookies.get("token"), brand);
            dispatch(SetBrandsProduct(response.data, brand));
            dispatch(SetLoadingStates({ isLoadingBrandProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getBrandsNames = (cookies: Cookies): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBrands: true }));

            const response = await dashboardAPI.getBrands(cookies.get("token"));
            dispatch(SetBrands(response.data));

            dispatch(SetLoadingStates({ isLoadingBrands: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductAction = (token: string, country_code?: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: true }));
            let response = await dashboardAPI.getProductAction(token, country_code);
            dispatch(SetProductActionCount(response.data.results));
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductActionAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    searchTerm: string,
    setProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            let response = await dashboardAPI.getProductActionAll(cookies.get("token"), items, offset, searchTerm, country_code, brand);
            dispatch(SetProductActionAllCount(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteRequiredActionProduct = (cookies: Cookies, id: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: true }));
            await dashboardAPI.deleteProduct(cookies.get("token"), id);
            dispatch(getProductAction(cookies.get("token")));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const ShipmentUploadedAction = (token: string, country_code: TSKUdropVendorMarkets | null): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            let response = await dashboardAPI.ShipmentUploadedAction(token, country_code);
            dispatch(SetProductCount(response.data.results));
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const ShipmentUploadedActionAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    search: string,
    setUploadedProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            let response = await dashboardAPI.ShipmentUploadedActionAll(cookies.get("token"), items, offset, search, country_code, brand);
            console.log(response.data);
            dispatch(SetProductCountAll(response.data.results));
            setUploadedProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const addProductFromAmazon = (amazonProducts: AddProductFromAmazonRequest[] | null, token: string, navigate: NavigateFunction): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await dashboardAPI.addProductFromAmazon(amazonProducts, token);
            navigate("/dashboard");
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding the products");
            }
        }
    };
};

export const addProductFromAmazonWithCategories = (
    amazonProducts: AddProductFromAmazonRequest[] | null,
    categories: AddProductFromAmazonCategoriesType[],
    token: string,
    navigate: NavigateFunction
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetFilesUploading(true));
            await dashboardAPI.addProductFromAmazonCategories(categories, token);
            dispatch(SetFilesUploading(false));

            dispatch(SetLoading(true));
            await dashboardAPI.addProductFromAmazon(amazonProducts, token);
            navigate("/dashboard");
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding the products");
            }
        }
    };
};

export const updateProductFromAmazon = (cookies: Cookies, fast_mode: boolean): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            await dashboardAPI.updateProductFromAmazon(fast_mode, cookies.get("token"));
            dispatch(getIntegrationsList(cookies.get("token")));

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong during a products update");
            }
        }
    };
};

export const importCustomProductAmazon = (cookies: Cookies, sku_list: string[]): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            await dashboardAPI.importCustomProductAmazon(sku_list, cookies.get("token"));
            dispatch(getIntegrationsList(cookies.get("token")));

            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when importing products");
            }
        }
    };
};

export const getUploadSkusTemplate = (integrationId: string): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetIsLoadingSkusFile(true));

            const cookies = getState().auth.cookies;

            const response = await dashboardAPI.getUploadSkusTemplate(integrationId, cookies.get("token"));

            window.open(response.data);

            dispatch(SetIsLoadingSkusFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsLoadingSkusFile(false));
                displayError(e.response.data, "Something went wrong while downloading template file");
            }
        }
    };
};

export const uploadFileWithSkus = (integration: TAddProductIntegration, formData: FormData): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetIsLoadingSkusFile(true));

            const response = await dashboardAPI.postUploadBulkFile(integration.id, getState().auth.cookies.get("token"), formData);

            if (response.data.file_data?.length && !response.data.async_status) {
                dispatch(SetSkusUploadFileResponse(response.data.file_data));
            } else if (response.data.async_status) {
                dispatch(SetLoadingStates({ [`isLoading${integration.country_code}AsyncBulkUpload`]: true }));
            }

            dispatch(SetIsLoadingSkusFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsLoadingSkusFile(false));
                displayError(e.response.data, "Something went wrong while uploading file");
            }
        }
    };
};

export const postCreateVendorTemplateProduct = (formData: FormData, integration: TAddProductIntegration, initialFetchVendorProducts: () => Promise<void>): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingCreatingVendorProduct: true }));

            const cookies = getState().auth.cookies;

            await addProductsOrBrandsAPI.postCreateVendorTemplateProduct(cookies, formData);

            if (!integration.products_available_to_upload) {
                await dispatch(getIntegrationsList(cookies.get("token")));
            } else {
                await initialFetchVendorProducts();
            }

            dispatch(SetLoadingStates({ isLoadingCreatingVendorProduct: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsLoadingSkusFile(false));
                displayError(e.response.data, "Something went wrong while uploading file");
            }
        }
    };
};

export const patchEditVendorTemplateProduct = (product_id: string, formData: FormData, onSuccess: () => void): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingCreatingVendorProduct: true }));

            const cookies = getState().auth.cookies;

            await addProductsOrBrandsAPI.patchEditVendorTemplateProduct(cookies, product_id, formData);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingCreatingVendorProduct: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingCreatingVendorProduct: false }));
                displayError(e.response.data, "Something went wrong while uploading file");
            }
        }
    };
};

export const getVendorMappingIntegrations = (queryParams?: Partial<{ source_type: TIntegrationSourceTypes; limit: number; vendor_product_id: string }>): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingMappingIntegrations: true }));

            const cookies = getState().auth.cookies;

            const response = await vendorProductsAPI.getVendorMappingIntegrations(cookies, queryParams);

            dispatch(SetVendorMappingIntegrations(response.data.results));

            dispatch(SetLoadingStates({ isLoadingMappingIntegrations: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const postImportVendorProductsToPlatform = (formData: FormData, onSuccess: () => void): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingVendorProductsImport: true }));

            const cookies = getState().auth.cookies;

            await vendorProductsAPI.postImportVendorProductsToPlatform(cookies, formData);

            await dispatch(getVendorMappingIntegrations({ source_type: "Amazon", limit: 20 }));

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingVendorProductsImport: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingVendorProductsImport: false }));
                displayError(e.response.data, "Something went wrong while importing vendor products");
            }
        }
    };
};

export const postRemapVendorProduct = (formData: FormData, product_brand: string, activeMarket: TSKUdropVendorMarkets | null, onSuccess: () => void): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: true }));

            const cookies = getState().auth.cookies;

            await vendorProductsAPI.postRemapVendorProduct(cookies, formData);

            onSuccess();

            const promises = [dispatch(ShipmentUploadedAction(cookies.get("token"), activeMarket)), dispatch(getBrandProduct(cookies, product_brand))];

            await Promise.all(promises);

            dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingVendorProductsRemapping: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getBulkVendorProductsTemplateFile = (): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: true }));

            const cookies = getState().auth.cookies;

            const response = await vendorProductsAPI.getBulkVendorProductsTemplateFile(cookies);

            window.open(response.data.url || "", "_blank");

            dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postBulkVendorProductsTemplateUpload = (formData: FormData, onSuccess: () => void): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: true }));

            const cookies = getState().auth.cookies;

            await vendorProductsAPI.postBulkVendorProductsTemplateUpload(cookies, formData);

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingVendorBulkProductsTemplate: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const getBulkVendorProductsUploadFile = (queryParams: { integration__id: string }): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: true }));

            const cookies = getState().auth.cookies;

            const response = await vendorProductsAPI.getBulkVendorProductsUploadFile(cookies, queryParams);

            window.open(response.data || "", "_blank");

            dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const postBulkVendorProductsUpload = (formData: FormData, queryParams: { integration__id: string }, onSuccess: () => void): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: true }));

            const cookies = getState().auth.cookies;

            const response = await vendorProductsAPI.postBulkVendorProductsUpload(cookies, queryParams, formData);

            if (response.data.file_data?.length && !response.data.async_status) {
                dispatch(SetVendorBulkProductsData(response.data.file_data));
            } else if (response.data.async_status) {
                dispatch(SetLoadingStates({ isLoadingVSAsyncBulkUpload: true }));
            }

            onSuccess();

            dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingBulkVendorProductsDataUpload: false }));
                displayError(e.response.data);
            }
        }
    };
};

export const waitForCompletionProductsImport = (): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;

            for (let i = 0; i < 15; i++) {
                let response = await dashboardAPI.getIntegrationsList(cookies.get("token"));

                if (response.data.results.some((el) => el.task_status === "FN" || el.task_status === "FR")) {
                    dispatch(getIntegrationsList(cookies.get("token")));

                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, 3000));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const waitForCompletionAsyncBulkUpload = (): AddProductThunkType => {
    return async (dispatch, getState) => {
        try {
            const cookies = getState().auth.cookies;
            const { integrationsList } = getState().addProduct;

            for (let i = 0; i < 10; i++) {
                let response = await dashboardAPI.getIntegrationsList(cookies.get("token"));

                if (response.data.results.some((el) => el.bulk_upload_status === "RC") && !integrationsList?.some((el) => el.bulk_upload_status === "RC")) {
                    dispatch(getIntegrationsList(cookies.get("token")));

                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, 3000));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export default productsReducer;
