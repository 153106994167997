import Cookies from "universal-cookie";

import {
    CreateShipmentPlanRequestType,
    GetAmazonProductRootObject,
    OwnFFOffAmazonProduct,
    TAmazonPlanSplits,
    TConfirmShippingPlanReqData,
    TGetFFDetailsResp,
    TOwnFFAwdPlanItem,
    TOwnFFPlanItem,
    TOwnFFToAmazonMarketplaces,
    TPostCreateFlatFileData,
    TSendToAmazonIntegration,
} from "./../redux/types/SendToAmazon/sendToAmazon.types";
import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestLazyParams, TRequestQueryParams } from "../redux/types/common/common.types";

export const sendToAmazon = {
    getAmazonFbaIntegration(cookies: Cookies, queryParams?: TRequestLazyParams & TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TSendToAmazonIntegration>>(`/amazon_mws/integrations/available/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllMarketplaces(cookies: Cookies) {
        return instance.get<{ marketplaces: TOwnFFToAmazonMarketplaces }>(`/shipments/own_ff_available_marketplaces/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAmazonProduct(cookies: Cookies, integration_id: string, searchTerm: string, items: number, offset: number, warehouse_id: string) {
        return instance.get<GetAmazonProductRootObject>(
            `/amazon_mws/shipment-plan/?integration_id=${integration_id}&warehouse_id=${warehouse_id}&limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOwnFFOffAmazonProducts(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<OwnFFOffAmazonProduct>>(`/shipments/own_ff/items-for-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFContainerBuilderProducts(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAmazonProductRootObject>(`/shipments/own_ff/items-for-flat-file${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    createShipmentPlan(cookies: Cookies, data: CreateShipmentPlanRequestType[], integrationId: string, warehouse_id: string) {
        return instance.post(
            `/amazon_mws/shipment-plan/`,
            {
                cartons: data,
                integration_id: integrationId,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    createUnconfirmedShipmentPlan(cookies: Cookies, data: CreateShipmentPlanRequestType[], integrationId: string) {
        return instance.post(
            `/amazon_mws/unconfirmed-shipment-plan/`,
            {
                cartons: data,
                integration_id: integrationId,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getShipmentPlan(cookies: Cookies, integration_id: string) {
        return instance.get<TAmazonPlanSplits>(`/amazon_mws/inbound-shipment/?integration_id=${integration_id}&limit=1444`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteUnconfirmedShipmentPlan(planId: string, cookies: Cookies) {
        return instance.delete(`/amazon_mws/unconfirmed-shipment-plan/${planId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    submitShipment(cookies: Cookies, data: TConfirmShippingPlanReqData) {
        return instance.post(`/amazon_mws/inbound-shipment/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateOwnFFOffAmazonShipmentPlan(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/own-ff-off-shipment/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCreateOwnFFToAmazonShipment(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/own-ff-to-shipment/ `, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getFFDetails(cookies: Cookies) {
        return instance.get<TGetFFDetailsResp>(`/shipments/ff_details/?limit=100`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    createFlatFile(cookies: Cookies, data: TPostCreateFlatFileData) {
        return instance.post<{ url: string }>(`/shipments/flat_file/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFPlans(cookies: Cookies, marketplace_id: string) {
        return instance.get<TGenericResponseFormat<TOwnFFPlanItem>>(`/shipments/own_ff_plans/?marketplace_id=${marketplace_id}&limit=1000`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteOwnFFPlans(cookies: Cookies, ids: string[]) {
        return instance.delete<string>(`/shipments/own_ff_plans/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: { ids },
        });
    },
    postProcessOwnFFToAmazonLabels(cookies: Cookies, data: FormData) {
        return instance.post<string>(`/shipments/own_ff_plan_file_details/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Own FF AWD shipments
    getOwnFFAwdPlans(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TOwnFFAwdPlanItem>>(`/shipments/own_ff_plans_awd/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteOwnFFAwdPlans(cookies: Cookies, idsToDelete: string[]) {
        return instance.delete<string>(`/shipments/own_ff_plans_awd/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
            data: { ids: idsToDelete },
        });
    },
    postProcessOwnFFToAmazonAwdLabels(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/own_ff_plans_awd/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postProcessOwnFFToAmazonCsv(cookies: Cookies, formData: FormData) {
        return instance.post<{ plan: string }>(`/shipments/own-ff-orders-file-upload-csv/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
