import Cookies from "universal-cookie";

import {
    SetIsLoadingType,
    SetTrackingColumnsType,
    SetTrackingListType,
    TTrackingColumnItem,
    TTrackingItem,
    TTrackingShipmentInformation,
    ThunkType,
    ThunkTypeWithResponse,
    TrackingActionTypes,
} from "./types/TrackingTypes/tracking.types";
import { trackingApi } from "../api/trackingAPI";

export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_TRACKING_LIST = "SET_TRACKING_LIST";
export const SET_TRACKING_COLUMNS = "SET_TRACKING_COLUMNS";

type InitialStateStatisticsType = {
    isLoading: boolean;
    trackingList: TTrackingItem[] | null;
    trackingColumns: TTrackingColumnItem[] | null;
};

const initialState: InitialStateStatisticsType = {
    isLoading: false,
    trackingList: null,
    trackingColumns: null,
};

const trackingReducer = (state = initialState, action: TrackingActionTypes): InitialStateStatisticsType => {
    switch (action.type) {
        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_TRACKING_LIST: {
            return {
                ...state,
                trackingList: action.data,
            };
        }
        case SET_TRACKING_COLUMNS: {
            return {
                ...state,
                trackingColumns: action.data,
            };
        }
        default: {
            return state;
        }
    }
};

export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_IS_LOADING,
    data: loading,
});
export const SetTrackingList = (data: TTrackingItem[]): SetTrackingListType => ({
    type: SET_TRACKING_LIST,
    data,
});
export const SetTrackingColumns = (data: TTrackingColumnItem[]): SetTrackingColumnsType => ({
    type: SET_TRACKING_COLUMNS,
    data,
});

export const getTrackingList = (cookies: Cookies, searchValue: string, filter: string, setIsNextPage: (value: boolean) => void, limit: number, offset: number): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await trackingApi.getTrackingList(cookies, searchValue, filter, limit, offset);
            dispatch(SetTrackingList(response.data.results));
            setIsNextPage(response.data.next !== null);
            dispatch(SetLoading(false));
        } catch (e) {
            dispatch(SetLoading(false));
            console.log(e);
        }
    };
};

export const getTrackingColumns = (cookies: Cookies, userId: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await trackingApi.getTrackingColumns(cookies, userId);
            dispatch(SetTrackingColumns(response.data.columns_for_tracking));
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateTrackingColumns = (cookies: Cookies, userId: string, columns: TTrackingColumnItem[]): ThunkType => {
    return async (dispatch) => {
        try {
            await trackingApi.updateTrackingColumns(cookies, userId, columns);
            dispatch(getTrackingColumns(cookies, userId));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTrackingMapInfo = (cookies: Cookies, trackingId: string): ThunkTypeWithResponse<TTrackingShipmentInformation | undefined> => {
    return async () => {
        try {
            const response = await trackingApi.getTrackingMapInfo(cookies, trackingId);
            return response;
        } catch (e) {
            console.log(e);
        }
    };
};

export default trackingReducer;
