import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestQueryParams } from "../redux/types/common/common.types";

import {
    TPostPrepOrders,
    TOwnFFOrderToPrep,
    TGetOrdersToPrepGeneral,
    PrepOrdersResult,
    TGetOrdersToShipGeneral,
    GetOrderToShipResult,
    TOwnFFOrderToShip,
    TPatchBulkPickedUpOrdersToShipReqData,
    TPatchBulkLoadedOrdersToShipReqData,
    TWarehouseMarkets,
    TPostOrdersToShipVerificationFile,
    TPostBulkCompletedOrdersToPrep,
    TQuoteOrderToPrep,
    TPostCompletedQuoteOrderToPrep,
    TPatchQuoteOrders,
    TSKUdropOrderToTrack,
    SetQuoteTrackingIdListType,
    TPatchUpdateQuoteTrackingIdsReqData,
    TQuoteOrderToShip,
    TGetQuoteOrdersToShipGeneral,
    TPatchQuoteLoadedOrdersToShipReqData,
    TTariffManagementResponse,
    TQuoteTariffOrder,
    TQuoteOrder,
    TGeQuoteOrdersGeneral,
    TOwnFFPastOutgoingOrdersData,
    TQuoteOrderToPrepDetails,
    TQuoteOrderToShipDetails,
    TPastQuoteOutgoingOrderDetails,
    TQuoteOrderToTrackDetails,
    TQuoteOrderDetails,
    TQuoteTariffOrderDetails,
    GetItemsForUkContainerRootObject,
    PatchUpdateDeliveryWindowReqData,
    TPatchSetOrderTrackingIDsReqData,
} from "./../redux/types/WarehouseTypes/warehouse.types";
import {
    AdminTotalsResponse,
    CartonsStored,
    ConfirmAuthorizedUserResponse,
    CreateCustomerCommunicationType,
    GetAllContainersRootObject,
    GetAllIncomingRootObject,
    GetAllOutgoingRootObject,
    GetIncomingShipmentsRootObject,
    GetItemsForContainerRootObject,
    GetShipFromListRootObject,
    GetTariffListRootObject,
    GetTenantsListResponseType,
    GetTrackingIdListRootObject,
    GetUserListRootObject,
    GetUserRoleListRootObject,
    GetWarehouseListRootObject,
    PatchProductListRequestType,
    PostAddressReqType,
    ProductListFileBodyType,
    SetShippedDateType,
    TPostCompletedOrderToPrep,
    TPostCompletedOwnFFOrderToShip,
} from "../redux/types/WarehouseTypes/warehouse.types";

export const incomingShipments = {
    getIncomingShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetIncomingShipmentsRootObject>(`/shipments/admin/incoming-shipments/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastIncomingOrders(
        cookies: Cookies,
        search: string,
        startDate: string | undefined,
        endDate: string | undefined,
        market: string,
        sortBy: string,
        items?: number,
        offset?: number
    ) {
        return instance.get<GetAllIncomingRootObject>(
            `/shipments/admin/all_incoming/?limit=${items ? items : 5}${offset ? `&offset=${offset}` : ""}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${market && market !== "ALL" ? `&shipment_items__integration__country_code=${market}` : ""}${startDate ? "&fromdate=" + startDate : ""}${endDate ? "&todate=" + endDate : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    deleteIncomingShipments(cookies: Cookies, id: string) {
        return instance.delete(`/shipments/incoming-shipments/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkIncomingShipment(token: string, id: string) {
        return instance.get(`/shipments/check/incoming-shipemnt/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    downloadIncomingTotalFile(cookies: Cookies, search: string, startDate: string, endDate: string, market: string, sortBy: string) {
        return instance.post<unknown, { data: { url: string } }>(
            `/shipments/admin/incoming_total_file/?${search !== "" ? `search=${window.encodeURIComponent(search)}` : ""}${market && market !== "ALL" ? `&country_code=${market}` : ""}${startDate ? "&date_from=" + startDate : ""}${endDate ? "&date_to=" + endDate : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            null,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const orderToShip = {
    getOrdersToShip(cookies: Cookies, shipMarket: string, sortBy: string, warehouseCustomID: string) {
        return instance.get<TGetOrdersToShipGeneral<GetOrderToShipResult>>(
            `/shipments/admin/order-to-ship/?${shipMarket && shipMarket !== "ALL" ? `integration__country_code=${shipMarket}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOrdersToShipAll(cookies: Cookies, shipMarket: string, searchTerm: string, sortBy: string, items: number, offset: number, warehouseCustomID: string) {
        return instance.get<TGetOrdersToShipGeneral<GetOrderToShipResult>>(
            `/shipments/admin/order-to-ship/?limit=${items ? items : 20}&offset=${offset}${shipMarket && shipMarket !== "ALL" ? `&integration__country_code=${shipMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getPastOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllOutgoingRootObject>(`/shipments/admin/all_outgoing/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchOrderToShip(id: string, shipment_id: string, token: string) {
        return instance.patch(
            `/amazon_mws/inbound-shipment-carrier-status/${id}/`,
            { shipment_id: shipment_id },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    downloadSkudropOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`/shipments/admin/outgoing_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadOwnFFOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`shipments/admin/off_outgoing_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadQuoteOutgoingTotalFile(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.post<{ url: string }>(`manual_quoting/admin/all_quote_orders_total_file/${processRequestQueryParams(queryParams)}`, null, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOrdersToShip(cookies: Cookies, prepMarket: string, sortBy: string, warehouseCustomID: string) {
        return instance.get<TGetOrdersToShipGeneral<TOwnFFOrderToShip>>(
            `/shipments/admin/off-order-to-ship/?ordering=${sortBy}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOwnFFOrdersToShipAll(cookies: Cookies, prepMarket: string, search: string, sortBy: string, items: number, offset: number, warehouseCustomID: string) {
        return instance.get<TGetOrdersToShipGeneral<TOwnFFOrderToShip>>(
            `/shipments/admin/off-order-to-ship/?limit=${items}&offset=${offset}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchProofsOwnFFOrderToShip(cookies: Cookies, orderId: string, data: FormData) {
        return instance.patch(`/shipments/admin/off-order-to-ship/${orderId}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedOwnFFOrderToShip(cookies: Cookies, data: TPostCompletedOwnFFOrderToShip) {
        return instance.post(`/shipments/admin/off-order-to-ship/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastOwnFFOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TOwnFFPastOutgoingOrdersData>(`/shipments/admin/all_off_outgoing/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastQuoteOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<GetAllOutgoingRootObject>(`/manual_quoting/admin/all_quote_orders/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Bulk Management
    patchBulkPickedUpOrdersToShip(cookies: Cookies, data: TPatchBulkPickedUpOrdersToShipReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment-carrier-status/bulk-update/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchBulkLoadedOrdersToShip(cookies: Cookies, data: TPatchBulkLoadedOrdersToShipReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment/bulk-update/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToShipBulkManagement(
        cookies: Cookies,
        mode: "picked_up" | "loaded" | "verification_file",
        shipMarket: TWarehouseMarkets,
        searchTerm: string,
        sortBy: string,
        items: number,
        offset: number
    ) {
        return instance.get<TGenericResponseFormat<GetOrderToShipResult>>(
            `/shipments/admin/${mode === "picked_up" || mode === "verification_file" ? "order-ready-to-pick-up" : "order-ready-to-load"}/?limit=${items ? items : 20}&offset=${offset}${shipMarket && shipMarket !== "ALL" ? `&integration__country_code=${shipMarket}` : ""}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    // Verification file
    postOrdersToShipVerificationFile(cookies: Cookies, data: TPostOrdersToShipVerificationFile) {
        return instance.post<{ url: string }>("/shipments/admin/generate_verification_document/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getOrdersToShipQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetQuoteOrdersToShipGeneral<TQuoteOrderToShip>>(`/manual_quoting/quote-order-to-ship/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchQuotePickedUpOrdersToShip(cookies: Cookies, id: string) {
        return instance.patch(
            `/manual_quoting/quote-order-to-ship/${id}/`,
            { id },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchQuoteLoadedOrdersToShip(cookies: Cookies, data: TPatchQuoteLoadedOrdersToShipReqData) {
        return instance.patch(`/manual_quoting/load-order-to-ship/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteShipOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToShipDetails>(`/manual_quoting/quote-order-to-ship-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastQuoteOutgoingOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TPastQuoteOutgoingOrderDetails>(`/manual_quoting/admin/all_quote_orders-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const productList = {
    getProductList(cookies: Cookies, storedMarket: string, warehouseCustomID: string) {
        return instance.get<CartonsStored>(
            `/shipments/admin/product-stored/${warehouseCustomID === "" ? "" : `?warehouse_custom_id=${warehouseCustomID}`}${storedMarket && storedMarket !== "ALL" ? `&integration__country_code=${storedMarket}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getProductListAll(cookies: Cookies, items: number, offset: number, search: string, storedMarket: string, warehouseCustomID: string) {
        return instance.get<CartonsStored>(
            `/shipments/admin/product-stored/?limit=${items ? items : 20}&offset=${offset}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${storedMarket && storedMarket !== "ALL" ? `&integration__country_code=${storedMarket}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchProductList(cookies: Cookies, data: PatchProductListRequestType, id: string) {
        return instance.patch(`/shipments/admin/product-stored/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const AdminTotals = {
    getAdminTotals(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<AdminTotalsResponse>(`/shipments/admin/totals/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    downloadProductListReport(cookies: Cookies, body: ProductListFileBodyType) {
        return instance.post<unknown, { data: { url: string } }>(`/shipments/admin/storage_tracking_file/`, body, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const trackingId = {
    getTrackingIdList(cookies: Cookies, trackingMarket: string, sortBy: string, warehouseCustomID: string) {
        return instance.get<GetTrackingIdListRootObject<TSKUdropOrderToTrack>>(
            `/shipments/admin/order-tracking/?${trackingMarket && trackingMarket !== "ALL" ? `integration__country_code=${trackingMarket}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getTrackingIdListAll(cookies: Cookies, items: number, offset: number, search: string, sortBy: string, market: string, warehouseCustomID: string) {
        return instance.get<GetTrackingIdListRootObject<TSKUdropOrderToTrack>>(
            `/shipments/admin/order-tracking/?limit=${items}&offset=${offset}&search=${window.encodeURIComponent(search)}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${market && market !== "ALL" ? `&integration__country_code=${market}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchSetOrderTrackingIDs(cookies: Cookies, data: TPatchSetOrderTrackingIDsReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment/transport/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postUploadBulkTrackingIDsFile(cookies: Cookies, data: FormData) {
        return instance.post(`/amazon_mws/bulk-transport-update`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setShippedDate(cookies: Cookies, id: string, data: SetShippedDateType) {
        return instance.patch(`/shipments/outgoing_shipment/update_departure_date/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchUpdateDeliveryWindow(cookies: Cookies, activeWindowID: string, reqData: PatchUpdateDeliveryWindowReqData) {
        return instance.patch(`/amazon_mws/delivery-window/${activeWindowID}/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getTrackingIdQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get(`/manual_quoting/quote-order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getTrackingIdQuotingAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get(`/manual_quoting/quote-order-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setQuoteShippedDate(cookies: Cookies, id: string, data: SetShippedDateType) {
        return instance.patch(`/manual_quoting/update_quote_departure_date/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    setQuoteTrackingIdList(cookies: Cookies, data: SetQuoteTrackingIdListType) {
        return instance.patch(`/manual_quoting/set_tracking_id/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateQuoteTrackingIdLis(cookies: Cookies, data: TPatchUpdateQuoteTrackingIdsReqData) {
        return instance.patch(`/manual_quoting/update_tracking_id/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteTrackingIdDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToTrackDetails>(`/manual_quoting/quote-order-details-tracking/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const orderToPrep = {
    getOrdersToPrep(cookies: Cookies, prepMarket: string, sortBy: string, warehouseCustomID: string) {
        return instance.get<TGetOrdersToPrepGeneral<PrepOrdersResult>>(
            `/shipments/admin/order-to-prep/?ordering=${sortBy}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOrdersToPrepAll(cookies: Cookies, prepMarket: string, search: string, sortBy: string, items: number, offset: number, warehouseCustomID: string) {
        return instance.get<TGetOrdersToPrepGeneral<PrepOrdersResult>>(
            `/shipments/admin/order-to-prep/?limit=${items ? items : 20}&offset=${offset}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postOrderToPrep(cookies: Cookies, reqData: TPostPrepOrders) {
        return instance.post("/shipments/admin/order-to-prep/", reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    checkOrderLabels(cookies: Cookies, id: string) {
        return instance.patch(
            `/shipments/restart-job-get-labels/${id}/`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOwnFFOrdersToPrep(cookies: Cookies, prepMarket: string, sortBy: string, warehouseCustomID: string) {
        return instance.get<TGetOrdersToPrepGeneral<TOwnFFOrderToPrep>>(
            `/shipments/admin/off-order-to-prep/?ordering=${sortBy}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getOwnFFOrdersToPrepAll(cookies: Cookies, prepMarket: string, search: string, sortBy: string, items: number, offset: number, warehouseCustomID: string) {
        return instance.get<TGetOrdersToPrepGeneral<TOwnFFOrderToPrep>>(
            `/shipments/admin/off-order-to-prep/?limit=${items}&offset=${offset}${prepMarket && prepMarket !== "ALL" ? `&integration__country_code=${prepMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    patchPickUpOwnFFOrderToPrep(cookies: Cookies, id: string, data: { pick_up_time: string }) {
        return instance.patch<TOwnFFOrderToPrep>(`/shipments/admin/off-order-to-prep/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedOwnFFOrderToPrep(cookies: Cookies, data: TPostCompletedOrderToPrep) {
        return instance.post(`/shipments/admin/off-order-to-prep/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Bulk management
    postBulkCompletedOrdersToPrep(cookies: Cookies, data: TPostBulkCompletedOrdersToPrep) {
        return instance.post("/shipments/admin/order-to-prep/bulk-create/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Orders
    getOrdersToPrepQuoting(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TQuoteOrderToPrep>>(`/manual_quoting/quote-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOrdersToPrepQuotingAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGetOrdersToPrepGeneral<TQuoteOrderToPrep>>(`/manual_quoting/quote-order-to-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postCompletedQuoteOrderToPrep(cookies: Cookies, data: TPostCompletedQuoteOrderToPrep) {
        return instance.post(`/manual_quoting/quote-order-to-prep/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuotePrepOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderToPrepDetails>(`/manual_quoting/quote-order-to-prep-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const tariffList = {
    getTariffList(cookies: Cookies, shipmentType: string | null, tariffMarket: string, warehouseCustomID: string) {
        return instance.get<GetTariffListRootObject>(
            `/shipments/admin/tariff-management/?${shipmentType ? `outgoing_orders__shipment_speed_type=${shipmentType}` : ""}${tariffMarket !== "ALL" ? `&outgoing_orders__integration__country_code=${tariffMarket}` : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getTariffListAll(cookies: Cookies, shipmentType: string | null, items: number, offset: number, search: string, tariffMarket: string, warehouseCustomID: string) {
        return instance.get<GetTariffListRootObject>(
            `/shipments/admin/tariff-management/?limit=${items ? items : 10}&offset=${offset}${shipmentType ? "&outgoing_orders__shipment_speed_type=" + shipmentType : ""}${warehouseCustomID === "" ? "" : `&warehouse_custom_id=${warehouseCustomID}`}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${tariffMarket && tariffMarket !== "ALL" ? `&outgoing_orders__integration__country_code=${tariffMarket}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getAllContainers(cookies: Cookies, tariffMarket: "US" | "UK") {
        return instance.get<GetAllContainersRootObject>(`/shipments/admin/tariff-container/?limit=1444&country_code=${tariffMarket}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getItemsForContainer(cookies: Cookies, containerId: string) {
        return instance.get<GetItemsForContainerRootObject>(`/shipments/admin/tariff-container/${containerId}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getItemsForUkContainer(cookies: Cookies, containerId: string, queryParams: TRequestQueryParams) {
        return instance.get<GetItemsForUkContainerRootObject>(`/shipments/admin/tariff-container-uk/${containerId}/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAllItemsForContainer(cookies: Cookies, containerId: string, items: number, offset: number, search: string, sortBy: string) {
        return instance.get<GetItemsForContainerRootObject>(
            `/shipments/admin/tariff-container/${containerId}/?limit=${items ? items : 10}&offset=${offset}&search=${window.encodeURIComponent(search)}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getTariffManagementQuoteOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TTariffManagementResponse<TQuoteTariffOrder>>(`/manual_quoting/tariff-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postSetQuoteOrderTotalTariff(cookies: Cookies, reqData: { order_id: string; total_price: number }) {
        return instance.post(`/manual_quoting/tariff-total/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postChargeQuoteOrderTariff(cookies: Cookies, reqData: { outgoing_shipment_ids: string[] }) {
        return instance.post(`/manual_quoting/tariff-management/`, reqData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Manual Quoting: Order Details
    getQuoteTariffOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteTariffOrderDetails>(`/manual_quoting/tariff-details-management/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const tenantList = {
    getUserList(cookies: Cookies) {
        return instance.get<GetUserListRootObject>(`/tenants/user-tenants-list/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserListAll(cookies: Cookies, items: number, offset: number, search: string, sortBy?: string) {
        return instance.get<GetUserListRootObject>(
            `/tenants/user-tenants-list/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}${sortBy ? `&ordering=${sortBy}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getWarehouseList(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<GetWarehouseListRootObject>(`/warehouses/warehouse-storage/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getWarehouseListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<GetWarehouseListRootObject>(
            `/warehouses/warehouse-storage/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getShipFromList(cookies: Cookies) {
        return instance.get<GetShipFromListRootObject>(`/tenants/ship-from-address/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getShipFromListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<GetShipFromListRootObject>(
            `/tenants/ship-from-address/?limit=${items}&offset=${offset}${search !== "" ? `&search=${window.encodeURIComponent(search)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postAddress(cookies: Cookies, data: PostAddressReqType) {
        return instance.post(`/tenants/ship-from-address/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchAddress(id: string, data: PostAddressReqType, cookies: Cookies) {
        return instance.patch(`/tenants/ship-from-address/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAddress(cookies: Cookies, id: string) {
        return instance.delete(`/tenants/ship-from-address/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteUserFromTennant(cookies: Cookies, id: string) {
        return instance.delete(`/tenants/tenants-manipulator/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const roleList = {
    getUserRoleList(cookies: Cookies) {
        return instance.get<GetUserRoleListRootObject>(`/users/set-role/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUserRoleListAll(cookies: Cookies, items: number, offset: number, search: string) {
        return instance.get<GetUserRoleListRootObject>(`/users/set-role/?limit=${items}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    updateUserRole(cookies: Cookies, id: string, role: string) {
        return instance.patch(
            `users/set-role/${id}/`,
            { role },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    addUserRole(cookies: Cookies, email: string, role: string) {
        return instance.post(
            `/users/invite/`,
            {
                role,
                email,
            },
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const userCommunicationAPI = {
    getTenantsList(token: string) {
        return instance.get<unknown, GetTenantsListResponseType>(`/notifications/tenant_list/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    createCustomerCommunication(data: CreateCustomerCommunicationType, token: string) {
        return instance.post(`/notifications/comunications/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
};

export const confirmAuthorizedUser = {
    ConfirmAuthorizedUserToTenant(id: string, token: string) {
        return instance.get<ConfirmAuthorizedUserResponse>(`/users/activate/${id}/${token}/`);
    },
};

export const quoteOrders = {
    getQuoteOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGeQuoteOrdersGeneral<TQuoteOrder>>(`/manual_quoting/quote-orders-requests/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchConfirmQuoteOrderPrice(cookies: Cookies, data: TPatchQuoteOrders, id: string) {
        return instance.patch(`/manual_quoting/quote-orders-requests/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteRequestDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOrderDetails>(`/manual_quoting/quote-orders-requests-details/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
