import Cookies from "universal-cookie";
import {
    AddProductFromAmazonCategoriesType,
    AddProductFromAmazonRequest,
    EditProductDataType,
    GetProductActionTypeAxios,
    TAddProductIntegration,
    ProductsFromAmazonRootObject,
    ShipmentUploadedActionTypeAxios,
    TPostUploadBulkFileResp,
    TBulkSkuUploadProductData,
    TVendorProduct,
    TAmazonMapProduct,
    TIntegrationSourceTypes,
    TVendorMappingIntegration,
    TPostBulkVendorProductsUploadData,
    TBulkVendorUploadProductData,
} from "../redux/types/AddProductTypes/AddProduct.types";
import {
    AddNotesIncomingShipmentsRequest,
    TPatchSubmitFinalWeightOrCbmReqData,
    TPostUkTariffAmountReqData,
    UpdateDownloadInfoRequest,
} from "../redux/types/WarehouseTypes/warehouse.types";
import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestLazySearchParams, TRequestQueryParams, TSKUdropVendorMarkets } from "../redux/types/common/common.types";

export const dashboardAPI = {
    getIntegrationsList(token: string) {
        return instance.get<TGenericResponseFormat<TAddProductIntegration>>("/integrations/products-integration/?limit=20", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrands(token: string) {
        return instance.get<string[]>(`/shipments/brand/dashboard/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getBrandProduct(token: string, brand: string) {
        return instance.get(`/shipments/brand-details/dashboard/?brand=${window.encodeURIComponent(brand)}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    ConfirmProductAction(formData: FormData, token: string) {
        return instance.post(`/shipments/admin/incoming-shipments/`, formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    AddNotesIncomingShipments(data: AddNotesIncomingShipmentsRequest, token: string) {
        return instance.post(`/shipments/notes/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    UpdateDownloadInfo(id: string, data: UpdateDownloadInfoRequest, token: string) {
        return instance.patch(`/shipments/admin/incoming-shipments/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    patchSubmitFinalWeightOrCbm(cookies: Cookies, data: TPatchSubmitFinalWeightOrCbmReqData) {
        return instance.patch(`/amazon_mws/inbound-shipment/${data.id}/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    ShipmentUploadedAction(token: string, country_code: TSKUdropVendorMarkets | null) {
        return instance.get<unknown, ShipmentUploadedActionTypeAxios>(`/shipments/uploaded/dashboard/?${country_code ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    ShipmentUploadedActionAll(token: string, items: number, offset: number, search: string, country_code?: string, brand?: string) {
        return instance.get<unknown, ShipmentUploadedActionTypeAxios>(
            `/shipments/uploaded/dashboard/?limit=${items ? items : 10}&offset=${offset}${search ? `&search=${window.encodeURIComponent(search)}` : ""}${country_code && country_code !== "ALL" ? `&integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    getProductAction(token: string, country_code?: string) {
        return instance.get<unknown, GetProductActionTypeAxios>(`/shipments/requires-actions/dashboard/?${country_code ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getProductActionAll(token: string, item: number, offset: number, searchTerm: string, country_code?: string, brand?: string) {
        return instance.get<unknown, GetProductActionTypeAxios>(
            `/shipments/requires-actions/dashboard/?limit=${item}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${country_code ? `&integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    editProduct(token: string, id: string, data: EditProductDataType) {
        return instance.patch(`/shipments/uploaded/dashboard/${id}/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteProduct(token: string, id: string) {
        return instance.delete(`/shipments/uploaded/dashboard/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    deleteHSCode(cookies: Cookies, id: string) {
        return instance.delete(`/shipments/hs-codes/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getProductsFromAmazon(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<ProductsFromAmazonRootObject>(`/amazon_mws/amazon/import-templates/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getUpdatedProductFromAmazon(token: string) {
        return instance.get("/amazon_mws/amazon/import-templates-updated/", {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    updateProductFromAmazon(fast_mode: boolean, token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates/",
            {
                fast_mode: fast_mode,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    importCustomProductAmazon(sku_list: string[], token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates/",
            {
                sku_list,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    addProductFromAmazon(products: AddProductFromAmazonRequest[] | null, token: string) {
        return instance.post(
            "/amazon_mws/amazon/import-templates-to-skudrop/",
            {
                products: products,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    createAndConfirmPaymentIntent(token: string, formData: FormData) {
        return instance.post("/shipments/admin/tariff-management/", formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    postChargeUKContainerTariff(cookies: Cookies, data: FormData) {
        return instance.post("/shipments/admin/tariff-management/", data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    calculateTariff(token: string, id: string | string[], filterType: string) {
        return instance.post("shipments/admin/upload-tariff/", filterType === "AIR" ? { outgoing_shipment_ids: id } : { container_id: id[0] }, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    patchTariffAmount(id: string | string[], tariff_amount: string, token: string) {
        return instance.patch(
            `/shipments/admin/tariff-management/${id}/`,
            { tariff_amount },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
    },
    postTariffUkAmount(data: TPostUkTariffAmountReqData, token: string) {
        return instance.post(`/shipments/admin/tariff-container-update/`, data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    checkOutgoingShipment(token: string, id: string) {
        return instance.get(`/shipments/check/outgoing-shipment/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    addProductFromAmazonCategories(data: AddProductFromAmazonCategoriesType[], token: string) {
        return instance.post("/shipments/upload_shipping_declaration/", data, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getTariffsForContainer(token: string, id: string) {
        return instance.get(`/shipments/admin/list_container_tariffs/${id}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getHSCodeInfo(token: string, query: string, countryCode: string) {
        return instance.get<unknown, { data: boolean }>(`/shipments/validate-hs-code/${countryCode}/${query}/`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getUploadSkusTemplate(integrationId: string, token: string) {
        return instance.get<unknown, { data: string }>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    postUploadBulkFile(integrationId: string, token: string, formData: FormData) {
        return instance.post<TPostUploadBulkFileResp>(`/amazon_mws/bulk-product-import/?integration__id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },
    getAsyncBulkUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TBulkSkuUploadProductData[]>(`/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAsyncBulkUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.post(
            `/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};

export const addProductsOrBrandsAPI = {
    postCreateVendorTemplateProduct(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/import-vendor-products/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    patchEditVendorTemplateProduct(cookies: Cookies, product_id: string, formData: FormData) {
        return instance.patch(`/shipments/import-vendor-products/${product_id}/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getVendorTemplateProducts(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TVendorProduct>>(`/shipments/import-vendor-products-to-skudrop/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const vendorProductsAPI = {
    getProductsForMapping(cookies: Cookies, queryParams?: Partial<{ vendor_product_id: string; integration_id: string } & TRequestLazySearchParams & TRequestQueryParams>) {
        return instance.get<TGenericResponseFormat<TAmazonMapProduct>>(`/shipments/vendor-products-map/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getVendorMappingIntegrations(cookies: Cookies, queryParams: Partial<{ source_type: TIntegrationSourceTypes } & TRequestQueryParams> = { limit: 20 }) {
        return instance.get<TGenericResponseFormat<TVendorMappingIntegration>>(`/shipments/integrations-uploaded-products/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postImportVendorProductsToPlatform(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/import-vendor-products-to-skudrop/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postRemapVendorProduct(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/vendor-products-map/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkVendorProductsTemplateFile(cookies: Cookies) {
        return instance.get<{ url: string }>(`/shipments/vendor-template-bulk/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkVendorProductsTemplateUpload(cookies: Cookies, formData: FormData) {
        return instance.post(`/shipments/vendor-template-bulk/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkVendorProductsUploadFile(cookies: Cookies, queryParams: { integration__id: string }) {
        return instance.get<string>(`/shipments/vendor-bulk-product-import/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkVendorProductsUpload(cookies: Cookies, queryParams: { integration__id: string }, formData: FormData) {
        return instance.post<TPostBulkVendorProductsUploadData>(`/shipments/vendor-bulk-product-import/${processRequestQueryParams(queryParams)}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getAsyncBulkVendorProductsUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.get<TBulkVendorUploadProductData[]>(`/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    deleteAsyncBulkVendorProductsUploadData(cookies: Cookies, queryParams?: TRequestQueryParams) {
        return instance.post(
            `/amazon_mws/bulk-product-upload-data/${processRequestQueryParams(queryParams || {})}`,
            {},
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
};
