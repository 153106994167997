import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import {
    IncomingAxiosResponseType,
    IncomingIntegrationResponseType,
    PrepareToShip,
    PrepareToShipResult,
    TBulkCommercialInvoiceItem,
    TOwnFFOutgoingOrder,
    TQuoteOutgoingOrder,
    TQuoteOutgoingOrderDetails,
    TSKUdropOutgoingOrdersResponse,
} from "../redux/types/IncomingTypes/incoming.types";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestQueryParams } from "../redux/types/common/common.types";
import { TWarehouse } from "../redux/types/WarehouseTypes/warehouse.types";

export const incomingProduct = {
    getIncomingProduct(cookies: Cookies, country_code?: string) {
        return instance.get<unknown, IncomingAxiosResponseType>(
            `/shipments/incoming-shipments/${country_code ? `?shipment_items__integration__country_code=${country_code}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    getIncomingProductAll(cookies: Cookies, items: number, offset: number, searchTerm: string, country_code?: string, brand?: string) {
        return instance.get<unknown, IncomingAxiosResponseType>(
            `/shipments/incoming-shipments/?limit=${items}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}${country_code ? `&shipment_items__integration__country_code=${country_code}` : ""}${brand ? `&brand=${window.encodeURIComponent(brand)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    deleteIncomingProduct(cookies: Cookies, id: string) {
        return instance.delete(`/shipments/incoming-shipments/${id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPrepareToShip(cookies: Cookies, integrationId: string, searchTerm: string, items: number, offset: number) {
        return instance.get<PrepareToShip>(
            `/shipments/prepare-to-ship/?integration_id=${integrationId}&limit=${items ? items : 20}&offset=${offset}${searchTerm ? `&search=${window.encodeURIComponent(searchTerm)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postPrepareToShip(cookies: Cookies, data: FormData) {
        return instance.post(`/shipments/prepare-to-ship/`, data, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
                "Content-Type": "multipart/form-data",
            },
        });
    },
    getIncomingIntegrations(cookies: Cookies) {
        return instance.get<unknown, { data: IncomingIntegrationResponseType }>(`/shipments/available_integrations_to_ship/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkCommercialInvoiceProducts(cookies: Cookies, integrationId: string, searchValue: string, items: number, offset: number) {
        return instance.get<TGenericResponseFormat<TBulkCommercialInvoiceItem>>(
            `/shipments/upload_commercial_invoice/?integration_id=${integrationId}&limit=${items || 20}&offset=${offset}${searchValue ? `&search=${window.encodeURIComponent(searchValue)}` : ""}`,
            {
                headers: {
                    Authorization: "Bearer " + cookies.get("token"),
                },
            }
        );
    },
    postBulkCommercialInvoice(cookies: Cookies, formData: FormData) {
        return instance.post<TGenericResponseFormat<TBulkCommercialInvoiceItem>>(`/shipments/upload_commercial_invoice/`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getBulkSkuInboundTemplate(cookies: Cookies, integrationId: string) {
        return instance.get<string>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    postBulkSkuInboundTemplate(cookies: Cookies, integrationId: string, formData: FormData) {
        return instance.post<PrepareToShipResult[]>(`/shipments/order-to-prep-file/?integration_id=${integrationId}`, formData, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getIncomingAvailableWarehouses(cookies: Cookies) {
        return instance.get<TGenericResponseFormat<TWarehouse>>(`/warehouses/user-warehouse-storage/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};

export const outgoingProduct = {
    getSKUdropOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TSKUdropOutgoingOrdersResponse>(`/shipments/outgoing-shipments/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getOwnFFOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TOwnFFOutgoingOrder>>(`/shipments/user/dashboard/off-shipment-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getQuoteOutgoingOrders(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TQuoteOutgoingOrder>>(`/manual_quoting/user/dashboard/manual-quote-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Quote Outgoing Order Details
    getQuoteOutgoingOrderDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TQuoteOutgoingOrderDetails>(`/manual_quoting/user/dashboard/manual-quote-details-prep/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
