import { TRequestQueryParams } from "../../redux/types/common/common.types";

export function processRequestQueryParams(params: TRequestQueryParams = {}) {
    const filteredParams = Object.fromEntries(
        Object.entries(params)
            .filter(([_, value]) => value || typeof value === "boolean") // filter out empty string, null and undefined values
            .map(([key, value]) => [key, (value as string | number | boolean).toString()]) // convert all values to string
    );

    return Object.keys(filteredParams).length ? "?" + new URLSearchParams(filteredParams).toString() : "";
}
